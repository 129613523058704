import $ from "jquery";

export function hcp() {

  let this_windowW = $(window).width();
  $(window).resize(function(){
    this_windowW =  $(window).width();
  })
  const dropdown_ft = () => {    
    //if(this_windowW < 768){
      $('.top-nav ul > li.nav-link.has-submenu > a').click(function(e){
        e.preventDefault();
        if($(this).parent().hasClass('opensub')){
          $(this).parent().removeClass('opensub');
        }else{
          $(this).parent().addClass('opensub');
        }
      })
    //}
  }

  // execute functions;
  dropdown_ft();
}

export function professional() {
    const getCookie = (name) => {
        //console.log('get cookie');
        const value = " " + document.cookie;
        const parts = value.split(" " + name + "=");
        return parts.length < 2 ? undefined : parts.pop().split(";").shift();
      };
    
      const setCookie = function (name, value, expiryDays, domain, path, secure) {
        const exdate = new Date();
        exdate.setHours(
          exdate.getHours() +
            (typeof expiryDays !== "number" ? 365 : expiryDays) * 24
        );
        document.cookie =
          name +
          "=" +
          value +
          ";expires=" +
          exdate.toUTCString() +
          ";path=" +
          (path || "/") +
          (domain ? ";domain=" + domain : "") +
          (secure ? ";secure" : "");
      };
    
      const $HCPBanner = document.querySelector(".hcp-professional-banner");
      const $HCPBannerButtons = $HCPBanner.querySelectorAll(".btn");
      const cookieName = "HCPBanner";
      const hasCookie = getCookie(cookieName);
      
      if (!hasCookie) {    
        $HCPBanner.classList.remove("hidden");
        document.body.dataset.modalOpen = "true";
      }
      
      $HCPBannerButtons.forEach(button => {
        button.addEventListener("click", (event) => {
          const urlPath = window.location.pathname;
          if (!urlPath.includes("/hcp")) {
            event.preventDefault();
          }
          setCookie(cookieName, "closed");
          document.body.dataset.modalOpen = "false";
          setTimeout(() => {
            $HCPBanner.remove();
          }, 500);
        });
      });
}